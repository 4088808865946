import smoothscroll from 'smoothscroll-polyfill'

import stateWrapper from 'state/wrapper'

// Polyfill smoothscrolling for Edge, Safari, IE, and Opera
smoothscroll.polyfill()

export const wrapRootElement = stateWrapper

// Stop gatsby from updating the scroll position when we are trying to fire our own scrollTo
export const shouldUpdateScroll = ({ routerProps: { location } }) => {
	const { search } = location
	return !search.includes('scrollTo=')
}
