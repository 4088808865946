// @flow
const initialState = { underlinedLink: null }

const SET_UNDERLINED_LINK = 'header/SET_UNDERLINED_LINK'

const setUnderlinedLink = (link: string) => ({
	type: SET_UNDERLINED_LINK,
	link,
})

export const actions = { setUnderlinedLink }

type State = { underlinedLink: ?string }
type Action = { type: string, link: string }

export const reducer = (state: State = initialState, action: Action) => {
	switch (action.type) {
		case SET_UNDERLINED_LINK:
			return { ...state, underlinedLink: action.link }
		default:
			return state
	}
}
